//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { documentLablePage, getLablePersonPage } from '@/api/modular/fileStream/documentLabelManage'
import { wx_org } from '@/api/modular/fileStream/user'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable, XCard } from '@/components'
import { Empty } from 'ant-design-vue'
export default {
    components: {
        STable,
        XCard,
        OpenData,
    },
    data() {
        return {
            visible: false,
            tags: [],
            tagActive: 0,
            tabActive: '1',
            sp_index: null,
            user_list_name: null,
            userId_arr: [],
            deptIds: {},
            // 查詢參數
            queryParam: {},
            // 表頭
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'userId',
                    width: '80px',
                    scopedSlots: { customRender: 'userId' },
                },
                {
                    title: '賬號',
                    dataIndex: 'action',
                    ellipsis: true,
                    scopedSlots: { customRender: 'action' },
                },
                {
                    title: '企業名稱',
                    dataIndex: 'corpName',
                    ellipsis: true,
                    scopedSlots: { customRender: 'corpName' },
                },
            ],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                return getLablePersonPage(
                    Object.assign(parameter, { labelId: this.tagActiveRow.id }, this.queryParam)
                ).then((res) => res.data)
            },
            orgTree: [],
            selectedRowKeys: [],
            selectedRows: [],
            selectedRows_new: [],
            sexDictTypeDropDown: [],
            statusDictTypeDropDown: [],
            treeLoading: true,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            replaceFields: {},
            options: {
                alert: {
                    show: false,
                    clear: () => {
                        this.selectedRowKeys = []
                        this.selectedRows_new = []
                    },
                },
                rowSelection: {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onSelectChange,
                },
            },
        }
    },
    created() {
        //this.getTree()
        this.sysDictTypeDropDown()
        this.getTags()
    },
    methods: {
        changeActive(tag, tagIndex) {
            this.tagActive = tagIndex
            this.tagActiveRow = tag
            this.$refs.table.refresh()
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        reset() {
            this.queryParam = {}
            this.$refs.table.refresh()
        },
        getTree() {
            /**
             * 獲取到機構樹，展開頂級下樹節點，考慮到後期數據量變大，不建議全部展開
             */
            wx_org(Object.assign(this.queryParam))
                .then((res) => {
                    this.treeLoading = false
                    if (!res.success) {
                        return
                    }
                    //
                    const mydata = Object.assign([], res.data)
                    function make_icon(data) {
                        data.forEach((element) => {
                            element.scopedSlots = { title: 'custom' }
                            element.children && element.children.length == 0
                                ? (element.slots = { icon: 'tag' })
                                : (element.slots = { icon: 'tags' })
                            if (element.children && element.children.length > 0) {
                                make_icon(element.children)
                            }
                        })
                    }
                    make_icon(mydata)
                    //
                    this.orgTree = mydata
                })
                .then((x) => {
                    init_wx()
                })
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        open(list, i, rows) {
            console.log(list, i)

            this.sp_index = i
            this.selectedRows_new = []
            this.userId_arr = []
            this.user_list_name = list
            this.selectedRows = rows
            this.selectedRows.map((x) => {
                this.userId_arr.push(x.userId)
            })
            console.log(this.selectedRows, this.userId_arr)
            //
            this.visible = true
        },
        sexFilter(sex) {
            const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
            if (values.length > 0) {
                return values[0].value
            }
        },
        statusFilter(status) {
            const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 獲取字典數據
         */
        sysDictTypeDropDown(text) {
            sysDictTypeDropDown({ code: 'sex' }).then((res) => {
                this.sexDictTypeDropDown = res.data
            })
            sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
                this.statusDictTypeDropDown = res.data
            })
        },

        /**
         * 點擊左側機構樹查詢列表
         */
        handleClick(it) {
            this.deptIds = { deptId: it.id, corpId: it.corpId }
            this.$refs.table.refresh(true)
        },

        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            //
            const select_new = [...this.selectedRows_new]

            const select = new Set([...select_new, ...selectedRows])
            const selected = Array.from(select)
            //
            const map = new Map()
            for (const item of selected) {
                if (!map.has(item.userId)) {
                    map.set(item.userId, item)
                }
            }
            const arr = [...map.values()]
            //
            const rows = arr.filter((x) => {
                return this.userId_arr.indexOf(x.userId) == -1
            })
            this.selectedRows_new = rows
        },
        handleSubmit() {
            const { selectedRows, selectedRows_new } = this
            const length = selectedRows.length + selectedRows_new.length
            if (length < 1) {
                this.$message.error('請選擇！')
            } else {
                const rows = [].concat(selectedRows).concat(selectedRows_new)
                console.log(rows)
                this.$emit('submit', rows)
                //
                this.visible = false
            }
        },

        handleCancel() {
            this.visible = false
        },
        del_user(i) {
            this.selectedRows_new.splice(i, 1)
        },
        callback(key) {
            this.tabActive = key
            this.$refs.table.refresh()
        },
        getTags() {
            documentLablePage().then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows
                    this.tagActiveRow = res.data.rows.length > 0 ? res.data.rows[0] : {}
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
    },
}
